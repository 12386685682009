<template>
  <div v-if="isLoaded" v-show="myOppsLoaded&&homeProfileLoaded" class="dashboard">
    <Suspense>
      <HomeProfile :reRender="reRender" @homeProfileLoaded="homeProfileLoaded = true"
                   @edit-profile="$emit('edit-profile')"/>
    </Suspense>
    <Suspense>
      <DashboardWish @dashboardWishLoaded="dashboardWishLoaded = true" @change-target-chatter="changeTargetChatter"/>
    </Suspense>

    <div class="middle-bar">
      <h2>Posted Opportunity</h2>
      <div class="search-bar">
        <div>
          <ion-icon class="small-icon" name="search-outline"></ion-icon>
        </div>
        <input
            v-model="search"
            name="search"
            placeholder="Search job title, companies, etc."
            type="text"
        />
      </div>
    </div>
    <div v-if="oppDataLength == 0" class="default-opp-column">
      <h3>You haven't posted any referrals for your juniors.</h3>
      <button @click="handlePostOpp">Post your first opportunity</button>
    </div>
    <div v-if="oppDataLength > 0" class="referral-box">
      <ul v-for="opps in modifiedOppData" :key="opps.id" class="referral-row">
        <OppCard
            v-for="opp in opps.data"
            :key="opp.id"
            :data="opp"
            @click="handleClick(opp.id)"
        />
        <NewOppCard
            v-if="opps.id == subArrayCounter - 1"
            @click="handlePostOpp"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import HomeProfile from "@/components/Home/homeProfile.vue";
import DashboardWish from "@/components/Dashboard/DashboardWish.vue";
import OppCard from "@/components/Opportunities/oppCard.vue";
import NewOppCard from "@/components/Dashboard/newOppCard.vue";
import {onMounted, ref, watchEffect} from "vue";
import {useRouter} from "vue-router";
import getUser from "@/composables/getUser";
import getMyPostOpps from "@/composables/Opp/getMyPostOpps";

export default {
  props: ["reRender"],
  emits: ["edit-profile", "change-target-chatter"],
  components: {
    HomeProfile,
    DashboardWish,
    OppCard,
    NewOppCard,
  },
  async setup(props, {emit}) {
    const {user} = getUser();
    const router = useRouter();
    const oppData = ref(null);
    const searchOppData = ref(null);
    const oppDataLength = ref(null);
    const subArrayCounter = ref(0);
    const modifiedOppData = ref(null);
    const search = ref(null);
    const noSpecial = ref(null);
    noSpecial.value = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/;
    const myOppsLoaded = ref(false);
    const homeProfileLoaded = ref(false)
    const isLoaded = ref(false);
    const dashboardWishLoaded = ref(false)

    onMounted(async () => {
      await getMyPostOpps(oppData, user.value.uid);

      watchEffect(() => {
        searchOppData.value = oppData.value;

        searchOppData.value = searchOppData.value.filter((opp) => {
          if (search.value == null) {
            return true;
          } else if (noSpecial.value.test(search.value)) {
            // console.log("no specials");
            return true;
          }
          return opp.title.toLowerCase().match(search.value.toLowerCase());
        });
        // console.log(searchOppData.value);

        const countOpps = (searchOppData) => {
          let oppCounter = 0;
          searchOppData.value.forEach((opp) => {
            oppCounter++;
          });
          // console.log(oppCounter);
          return oppCounter;
        };

        oppDataLength.value = countOpps(searchOppData);

        const modifyOppData = (searchOppData) => {
          let result = [];
          subArrayCounter.value = Math.ceil((oppDataLength.value + 1) / 3);
          for (let i = 0; i < subArrayCounter.value; i++) {
            result.push({data: [], id: i});
          }
          let counter = 0;
          searchOppData.value.forEach((opp) => {
            result[Math.floor(counter / 3)].data.push(opp);
            counter++;
          });
          return result;
        };

        modifiedOppData.value = modifyOppData(searchOppData);
      });

      myOppsLoaded.value = true;
      isLoaded.value = true;
    });

    const changeTargetChatter = (chatterId) => {
      emit("change-target-chatter", chatterId);
    }

    const handleClick = (oppId) => {
      router.push({name: "Senior-PostOppDetail", params: {id: oppId}});
    };

    const handlePostOpp = () => {
      router.push({name: "Senior-PostNewOpp"});
    };

    return {
      isLoaded,
      myOppsLoaded,
      homeProfileLoaded,
      dashboardWishLoaded,
      oppDataLength,
      search,
      subArrayCounter,
      modifiedOppData,
      handleClick,
      handlePostOpp,
      changeTargetChatter
    };
  },
};
</script>

<style scoped>
.dashboard {
  flex: 1 0 80%;
  padding: 5vh 8vh 8vh 8vh;
  animation: fadein 1s;
}

@media screen and (max-width: 600px) {
  .dashboard {
    padding: 0;
  }
}

.middle-bar {
  display: flex;
  flex-wrap: wrap;
  padding: 3vh 2vw;
}

.middle-bar h2 {
  flex: 0 1 50%;
  text-align: left;
  font-weight: bolder;
}

.search-bar {
  height: 5vh;
  flex: 1 0 auto;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .search-bar {
    margin-top: 20px;
  }
}

.search-bar div {
  flex: 0 1 10%;
  color: #808080;
  font-size: 80%;
  margin: auto;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.search-bar input,
.search-bar input:focus {
  display: block;
  flex: 1 0 auto;
  color: #808080;
  height: 4vh;
  background-color: white;
  border: none;
  outline: none;
}

.default-opp-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8vh;
}

.default-opp-column h3 {
  text-align: center;
  vertical-align: middle;
  margin: 8vh 0 4vh 0;
}

.default-opp-column button {
  text-align: center;
  vertical-align: middle;
  padding: 2vh 4vh;
  color: white;
  font-size: 120%;
  font-weight: 500;
  background-color: #cca2d8;
  border-radius: 4vh;
  border: none;
}

.default-opp-column button:hover {
  background-color: #c069d8;
  cursor: pointer;
}

.referral-row {
  list-style: none;
  display: flex;
}

@media screen and (max-width: 630px) {
  .referral-row {
    flex-direction: column;
  }
}
</style>
